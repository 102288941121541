.header {
  margin-bottom: 30px !important;
  background-color: white !important;
  padding: 0 22px 0 0 !important;
  border-radius: 6px !important;
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07) !important;
}

.header .header_navlink {
  padding: 0;
}

.header .header_link {
  font-weight: 500;
  padding: 10px !important;
  color: darkslategray !important;
}

.header .header_link:hover {
  color: var(--main-color) !important;
}

.header .header_link_active {
  font-weight: 500;
  padding: 10px !important;
  color: var(--main-color) !important;
}

.header .header_home {
  background-color: var(--main-color);
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.header .header_left {
  margin-right: auto;
}

.header .header_right {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0px 10px 10px !important;
}

.header .header_right > a > .MuiSvgIcon-root {
  font-size: 16px !important;
  margin-right: 12px;
  color: black;
}

.header .header_right .header_socials :hover {
  color: var(--main-color) !important;
}
